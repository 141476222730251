import React from 'react'
import { Result, Button } from 'antd';

const NotFound = () => {
        return (
            <div>
                <Result
                    status="404"
                    title="404"
                    subTitle="Üzgünüm, sayfayı bulamadım."
                    extra={<Button type="primary" href='/home'>Anasayfaya Dön</Button>}
                />
            </div>
        )
}

export default NotFound