import React, { useState, useEffect } from "react";
import { Layout, Select } from "antd";
import { Link } from "react-router-dom";

import { toggleCollapsedSideNav } from "../../appRedux/actions/Setting";
import { changeTenant } from "appRedux/actions/Auth";
import UserInfo from "components/UserInfo";
import Auxiliary from "util/Auxiliary";
import RequestHandler from "util/RequestHandler";

import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  TAB_SIZE,
} from "../../constants/ThemeSetting";
import { useDispatch, useSelector } from "react-redux";

const { Header } = Layout;

const Topbar = () => {
  const { navStyle } = useSelector(({ settings }) => settings);
  const { navCollapsed, width } = useSelector(({ common }) => common);
  const dispatch = useDispatch();
  const [tenantSelect, setTenantSelect] = useState({
    data: [],
    loading: false,
  });
  const [activeTenantId, setActiveTenantId] = useState();

  useEffect(() => {
    getTenants();
  }, []);

  const getTenants = () => {
    setTenantSelect({
      ...tenantSelect,
      loading: true,
    });
    RequestHandler.get(`/FirmAuth/GetTenants`).then((response) => {
      setTenantSelect({
        data: response,
        loading: false,
      });

      setActiveTenantId(localStorage.getItem("tenantId"));
    });
  };

  const onSelectTenant = async (tenantId) => {
    if (activeTenantId === tenantId) return;

    const response = await dispatch(changeTenant(tenantId));

    if (response?.success) {
      setActiveTenantId(tenantId);
    }
  };

  return (
    <Header>
      {navStyle === NAV_STYLE_DRAWER ||
      ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) &&
        width < TAB_SIZE) ? (
        <div className="gx-linebar gx-mr-3">
          <i
            className="gx-icon-btn icon icon-menu"
            onClick={() => {
              dispatch(toggleCollapsedSideNav(!navCollapsed));
            }}
          />
        </div>
      ) : null}
      {/* <Link to="/" className="gx-d-block gx-d-lg-none gx-pointer">
        <img alt="Paratic" src={require("assets/images/w-logo.png")} />
      </Link> */}

      <ul className="gx-header-notifications gx-ml-auto">
        <li className="gx-language">
          <Select
            loading={tenantSelect.loading}
            value={activeTenantId}
            onSelect={onSelectTenant}
            style={{ maxWidth: 300 }}
          >
            {tenantSelect.data.map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </li>
        {width >= TAB_SIZE ? null : (
          <Auxiliary>
            <li className="gx-user-nav">
              <UserInfo />
            </li>
          </Auxiliary>
        )}
      </ul>
    </Header>
  );
};

export default Topbar;
