import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  SET_USER_DATA,
} from "constants/ActionTypes";
import RequestHandler from "../../util/RequestHandler";
import { notification } from "antd";

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url,
  };
};

export const setUserToken = (token) => {
  return {
    type: SET_USER_DATA,
    payload: token,
  };
};

export const userSignUp = ({ email, password, name }) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    RequestHandler.post("/auth/register", {
      email: email,
      password: password,
      name: name,
    })
      .then(({ data }) => {
        if (data.result) {
          localStorage.setItem(
            "token",
            JSON.stringify(data.token.access_token)
          );
          RequestHandler.defaults.headers.common["authorization"] =
            "Bearer " + data.token.access_token;
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: SET_USER_DATA, payload: data.user });
        } else {
          dispatch({ type: FETCH_ERROR, payload: "Network Error" });
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const userSignIn = (requestData) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });

    return RequestHandler.post(`/FirmAuth/Login`, requestData, false).then(
      (response) => {
        if (response.success) {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem(
            "expiration",
            new Date(response.data.expiration).getTime()
          );
          localStorage.setItem("userId", response.data.firmUserId);
          localStorage.setItem("userName", response.data.name);
          localStorage.setItem("tenantId", response.data.tenantId);
          localStorage.setItem(
            "menu",
            JSON.stringify({
              paymentPlan: response.data.paymentPlan,
              collectionPlan: response.data.collectionPlan,
              posCollection: response.data.posCollection,
              stocks: response.data.stocks,
              dbsInvoices: response.data.dbsInvoices,
              invoiceDiscount: response.data.invoiceDiscount,
              erpReport: response.data.erpReport,
              createPaymentLink: response.data.createPaymentLink,
            })
          );
          RequestHandler.get(`/FirmAuth/GetActiveTenantInfo`).then(
            (response) => {
              if (response.success) {
                localStorage.setItem("tenantLogo", response.data.logoPath);
              }
            }
          );

          dispatch(setUserToken(response.data.token));

          notification.success({
            message: "Giriş başarılı.",
            duration: 2,
          });
        } else {
          dispatch({ type: FETCH_ERROR, payload: response.message });
        }
        return response;
      }
    );
  };
};

export const changeTenant = (tenantId) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });

    return RequestHandler.get(`/FirmAuth/ChangeTenant/${tenantId}`).then(
      (response) => {
        if (response.success) {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem(
            "expiration",
            new Date(response.data.expiration).getTime()
          );
          localStorage.setItem("userId", response.data.firmUserId);
          localStorage.setItem("userName", response.data.name);
          localStorage.setItem("tenantId", response.data.tenantId);
          localStorage.setItem(
            "menu",
            JSON.stringify({
              paymentPlan: response.data.paymentPlan,
              collectionPlan: response.data.collectionPlan,
              posCollection: response.data.posCollection,
              stocks: response.data.stocks,
              dbsInvoices: response.data.dbsInvoices,
              invoiceDiscount: response.data.invoiceDiscount,
              erpReport: response.data.erpReport,
              createPaymentLink: response.data.createPaymentLink,
            })
          );

          dispatch(setUserToken(response.data.token));

          dispatch({ type: FETCH_SUCCESS });
          RequestHandler.get(`/FirmAuth/GetActiveTenantInfo`).then(
            (response) => {
              if (response.success) {
                localStorage.setItem("tenantLogo", response.data.logoPath);
                window.location.reload();
              }
            }
          );
        } else {
          dispatch({ type: FETCH_ERROR, payload: response.message });
        }
        return response;
      }
    );
  };
};

export const userSignOut = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });

    localStorage.removeItem("token");
    localStorage.removeItem("expiration");
    localStorage.removeItem("userId");
    localStorage.removeItem("userName");
    localStorage.removeItem("tenantId");
    localStorage.removeItem("menu");
    localStorage.removeItem("tenantLogo");

    dispatch({ type: FETCH_SUCCESS });
    dispatch({ type: SIGNOUT_USER_SUCCESS });
  };
};
