const menuItems = [
  {
    key: "home",
    url: "/home",
    icon: "icon icon-home",
    title: "Ana Sayfa",
  },
  {
    key: "payment-plan",
    url: "/payment-plan",
    icon: "icon icon-datepicker",
    title: "Ödeme Planı",
    menuName: "paymentPlan",
  },
  {
    key: "collection-plan",
    url: "/collection-plan",
    icon: "icon icon-calendar",
    title: "Tahsilat Planı",
    menuName: "collectionPlan",
  },
  {
    key: "pos-collection",
    url: "/pos-collection",
    icon: "icon icon-litcoin",
    title: "Pos Tahsilatı",
    menuName: "posCollection",
  },
  {
    key: "pos-collection-transaction",
    url: "/pos-collection-transaction",
    icon: "icon icon-timepicker",
    title: "Tahsilat Geçmişi",
    menuName: "posCollection",
  },
  {
    key: "payment-request",
    url: "/payment-request",
    icon: "icon icon-litcoin",
    title: "Linkle Ödeme",
    menuName: "createPaymentLink",
  },
  {
    key: "payment-list",
    url: "/payment-list",
    icon: "icon icon-timepicker",
    title: "Link Takibi",
    menuName: "createPaymentLink",
  },
  {
    key: "stocks",
    url: "/stocks",
    icon: "icon icon-table",
    title: "Stoklar",
    menuName: "stocks",
  },
  {
    key: "dbs-invoices",
    url: "/dbs-invoices",
    icon: "icon icon-orders",
    title: "Dbs Faturaları",
    menuName: "dbsInvoices",
  },
  {
    key: "invoice-discount",
    url: "/invoice-discount",
    icon: "icon icon-revenue-new",
    title: "Fatura İskontolama",
    menuName: "invoiceDiscount",
  },
  {
    key: "erp-report",
    url: "/erp-report",
    icon: "icon icon-table",
    title: "Erp Rapor",
    menuName: "erpReport",
  },
];

export default menuItems;
