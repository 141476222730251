import React from "react";
import { Result } from "antd";

const PosCollectionResult = (props) => {
  const { success, message } = props.match.params;
  return (
    <Result status={success == "true" ? "success" : "error"} title={message} />
  );
};

export default PosCollectionResult;
