import React, { useState } from "react";
import { Menu, Row, Spin } from "antd";
import { Link } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE,
} from "../../constants/ThemeSetting";
import { useSelector } from "react-redux";

import menuItems from "util/MenuItems";

const SidebarContent = () => {
  let { pathname } = useSelector(({ common }) => common);
  let { navStyle, themeType } = useSelector(({ settings }) => settings);

  const userMenu = JSON.parse(localStorage.getItem("menu"));
  const getNoHeaderClass = (navStyle) => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return "gx-no-header-notifications";
    }
    return "";
  };
  const [permissions, setPermissions] = useState({
    loading: false,
  });

  const renderMenuItem = (menuItem) => {
    if (
      menuItem.isRender === false ||
      (menuItem.module && !permissions.modules.includes(menuItem.module))
    )
      return null;

    if (menuItem.menuName && userMenu[menuItem.menuName] === false) return null;

    if (menuItem.children) {
      return (
        <Menu.SubMenu
          key={menuItem.key}
          title={
            <span>
              <i className={menuItem.icon} />
              <span>{menuItem.title}</span>
            </span>
          }
        >
          {menuItem.children.map((childItem) => renderMenuItem(childItem))}
        </Menu.SubMenu>
      );
    } else {
      return (
        <Menu.Item key={menuItem.key}>
          <Link to={menuItem.url}>
            <i className={menuItem.icon} />
            <span>{menuItem.title}</span>
          </Link>
        </Menu.Item>
      );
    }
  };

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/")[1];

  return (
    <>
      <SidebarLogo />
      <div className="gx-sidebar-content">
        <div
          className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}
        >
          <UserProfile />
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
            mode="inline"
          >
            {permissions.loading ? (
              <Row justify="center">
                <Spin style={{ margin: 5 }} />
              </Row>
            ) : (
              menuItems.map((menuItem) => {
                return renderMenuItem(menuItem);
              })
            )}
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

SidebarContent.propTypes = {};
export default SidebarContent;
