import React, { useEffect, useState } from "react";
import { Button, Result, Form, Input } from "antd";
import { useHistory } from "react-router-dom";
import Screen from "../util/Screens";
import RequestHandler from "../util/RequestHandler";

const ResetPassword = (props) => {
  const history = useHistory();

  const [logoUrl, setLogoUrl] = useState();
  const [isHavePsrGuid, setIsHavePsrGuid] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    RequestHandler.get(
      `/FirmAuth/IsHavePsrGuid?PsrGuid=${props.match.params.psrGuid}`
    ).then((response) => {
      if (response.success) {
        setIsHavePsrGuid(true);
      }
    });
    let basePath = window.location.href.split("/reset-password")[0];
    let newLogoUrl = "";
    RequestHandler.get(`/FirmAuth/GetLoginEndpoints`).then((response) => {
      response.domain.map((item) => {
        if (basePath == item.endpoint) {
          newLogoUrl = item.logoUrl;
        }
      });
      if (newLogoUrl == "") {
        setLogoUrl(null);
      } else {
        setLogoUrl(newLogoUrl);
      }
    });
  }, []);

  const onFinishFailed = (errorInfo) => {
    setLoading(false);
  };

  const onFinish = (values) => {
    RequestHandler.post(`/FirmAuth/ResetPassword`, {
      PsrGuid: props.match.params.psrGuid,
      Password: values.password,
    }).then((response) => {
      if (response.success) {
        history.push("/");
      }
      setLoading(false);
    });
  };

  return isHavePsrGuid === true ? (
    <div className="gx-login-container" style={{ backgroundColor: "#f0f8ff" }}>
      <div className="gx-login-content">
        <div className="gx-login-header">
          {Screen.screen === 0 &&
            (logoUrl === undefined ? null : (
              <img
                src={
                  logoUrl === null
                    ? require("assets/images/finekralogo.png")
                    : logoUrl
                }
                alt="paratic"
                title="paratic"
                style={{ width: "40%" }}
              />
            ))}
          {Screen.screen === 1 && (
            <img
              src={require("assets/images/imzaPosLogo.png")}
              alt="paratic"
              title="paratic"
              style={{ width: "40%" }}
            />
          )}
        </div>
        <div className="gx-mb-4">
          <h3>Şifreyi Yenile</h3>
          <p>Hesabınızın yeni şifresini giriniz.</p>
        </div>

        <Form
          initialValues={{ remember: true }}
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="gx-signin-form gx-form-row0"
        >
          <Form.Item
            rules={[{ required: true, message: "Lütfen şifrenizi giriniz." }]}
            name="password"
          >
            <Input
              className="gx-input-lineheight"
              onKeyDown={(evt) => evt.keyCode === 32 && evt.preventDefault()}
              type="password"
              placeholder="Yeni Şifrenizi Giriniz"
            />
          </Form.Item>

          <Form.Item
            rules={[
              {
                required: true,
                message: "Lütfen yeni şifre tekrar alanını boş bırakmayın.",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("Girdiğiniz şifreler eşleşmiyor.")
                  );
                },
              }),
            ]}
            name="confirm"
          >
            <Input
              className="gx-input-lineheight"
              onKeyDown={(evt) => evt.keyCode === 32 && evt.preventDefault()}
              placeholder="Yeni Şifrenizi Tekrar Giriniz"
              type="password"
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => setLoading(true)}
              loading={loading}
            >
              Yenile
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  ) : (
    <div>
      <Result
        status="404"
        title="404"
        subTitle="Üzgünüm, böyle bir kullanıcı bulamadım."
        extra={
          <Button type="primary" href="/signin">
            {" "}
            Giriş Sayfasına Dön
          </Button>
        }
      />
    </div>
  );
};

export default ResetPassword;
