import React from 'react';
import { Result, Button } from 'antd';

const UnAuthorized = () => {
    return (
        <div>
           <Result
            status="403"
            title="403"
            subTitle="Üzgünüm bu sayfa için yetkiniz bulunmamaktadır."
            extra={<Button type="primary" href='/home'>Anasayfaya dön</Button>}
            />
        </div>
    )
}

export default UnAuthorized