import React, { useEffect, useState } from "react";
import { Button, Result, Row, Col, Form, Input } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import Screen from "../util/Screens";
import RequestHandler from "../util/RequestHandler";

const NewPassword = (props) => {
  const history = useHistory();
  const [logoUrl, setLogoUrl] = useState();
  const [isHavePsrGuid, setIsHavePsrGuid] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    RequestHandler.get(
      `/FirmAuth/IsHavePsrGuid?PsrGuid=${props.match.params.psrGuid}`
    ).then((response) => {
      if (response.success) {
        setIsHavePsrGuid(true);
      }
    });

    let basePath = window.location.href.split("/new-password")[0];
    let newLogoUrl = "";
    RequestHandler.get(`/FirmAuth/GetLoginEndpoints`).then((response) => {
      response.domain.map((item) => {
        if (basePath == item.endpoint) {
          newLogoUrl = item.logoUrl;
        }
      });
      if (newLogoUrl == "") {
        setLogoUrl(null);
      } else {
        setLogoUrl(newLogoUrl);
      }
    });
  }, []);

  const onFinishFailed = (errorInfo) => {
    setLoading(false);
  };

  const onFinish = (values) => {
    RequestHandler.post(`/FirmAuth/NewPassword`, {
      PsrGuid: props.match.params.psrGuid,
      Password: values.password,
    }).then((response) => {
      if (response.success) {
        history.push("/signin");
      }
      setLoading(false);
    });
  };

  return isHavePsrGuid ? (
    <div className="gx-login-container">
      <div className="gx-login-content">
        <div className="gx-login-header">
          {Screen.screen === 0 &&
            (logoUrl === undefined ? null : (
              <img
                src={
                  logoUrl === null
                    ? require("assets/images/finekralogo.png")
                    : logoUrl
                }
                alt="paratic"
                title="paratic"
                style={{ width: "40%" }}
              />
            ))}
          {Screen.screen === 1 && (
            <img
              src={require("assets/images/imzaPosLogo.png")}
              alt="paratic"
              title="paratic"
              style={{ width: "40%" }}
            />
          )}
        </div>
        <div className="gx-mb-4">
          <h3>Şifre Oluştur</h3>
          <p>Hesabınızın şifresini giriniz.</p>
        </div>

        <Form
          initialValues={{ remember: true }}
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="gx-signin-form gx-form-row0"
        >
          <Form.Item
            rules={[{ required: true, message: "Lütfen şifrenizi giriniz." }]}
            name="password"
          >
            <Input
              className="gx-input-lineheight"
              type="password"
              placeholder="Şifrenizi Giriniz"
              onKeyDown={(evt) => evt.keyCode === 32 && evt.preventDefault()}
            />
          </Form.Item>

          <Form.Item
            rules={[{ required: true, message: "Lütfen şifrenizi giriniz." }]}
            name="confirm"
          >
            <Input
              className="gx-input-lineheight"
              placeholder="Şifrenizi Tekrar Giriniz"
              type="password"
              onKeyDown={(evt) => evt.keyCode === 32 && evt.preventDefault()}
            />
          </Form.Item>
          <Row>
            <Col span={12}>
              <Form.Item>
                <Button
                  type="primary"
                  icon={<CheckOutlined />}
                  htmlType="submit"
                  onClick={() => setLoading(true)}
                  loading={loading}
                >
                  Oluştur
                </Button>
              </Form.Item>
            </Col>
            <Col span={12}>
              <a href="/signin">Giriş Sayfasına Dön</a>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  ) : (
    <div>
      <Result
        status="404"
        title="404"
        subTitle="Üzgünüm, böyle bir kullanıcı bulamadım."
        extra={
          <Button type="primary" href="/signin">
            {" "}
            Giriş Sayfasına Dön
          </Button>
        }
      />
    </div>
  );
};

export default NewPassword;
