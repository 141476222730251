import React from "react";
import { ColumnHelper } from "util/TableHelper";

import { Empty, Col, Row, Collapse, Card, List } from "antd";
import CardLogo from "./CardLogo";

const PosCommissionTable = (props) => {
  const { useCommissionsForAvailableInstallment } = props.data;

  const calculatePayableAmount = (
    amount,
    commissionRate,
    bankId,
    isCommissionFromDealer
  ) => {
    let payableAmount = amount;
    if (useCommissionsForAvailableInstallment) {
      payableAmount = amount + (amount / 100) * commissionRate;
    }

    if (isCommissionFromDealer && useCommissionsForAvailableInstallment) {
      payableAmount = (amount / (100 - commissionRate)) * 100;
    }

    if (bankId == 856) {
      payableAmount = amount / ((100 - commissionRate) / 100);
    }

    return payableAmount;
  };

  const renderCommissionRate = (data) =>
    data || data === 0 ? `% ${ColumnHelper.renderDecimal(data)}` : "-";

  const renderWithAmount = (commissions) => (
    <List size="small">
      <List.Item key={-1}>
        <Row justify="center" style={{ width: "20%" }}>
          <strong>Taksit</strong>
        </Row>
        <Row justify="center" style={{ width: "40%" }}>
          <strong>Aylık</strong>
        </Row>
        <Row justify="center" style={{ width: "40%" }}>
          <strong>Toplam</strong>
        </Row>
      </List.Item>

      {Object.keys(commissions)
        .filter((x) => x.startsWith("commissionRate" && !x.endsWith("Enabled")))
        .map((rate, i) => {
          const commissionRate = commissions[rate];
          if (!commissionRate && commissionRate !== 0) return;

          const totalInstallment = i + 1 + commissions.extraInstallmentCount;
          const payableAmount = calculatePayableAmount(
            props.amount,
            commissionRate,
            commissions.bankId,
            commissions.isCommissionFromDealer
          );
          const installmentAmount = payableAmount / totalInstallment;

          const extra =
            commissions.extraInstallmentCount > 0 ? (
              <span className="gx-text-success">{` + (${commissions.extraInstallmentCount})`}</span>
            ) : undefined;
          return (
            <List.Item key={i}>
              <Row justify="center" style={{ width: "20%" }}>
                {i === 0 ? (
                  <span>{i + 1}</span>
                ) : (
                  <span>
                    {i + 1}
                    {extra}
                  </span>
                )}
              </Row>
              <Row justify="center" style={{ width: "40%" }}>
                {ColumnHelper.renderDecimal(installmentAmount)}
              </Row>
              <Row justify="center" style={{ width: "40%" }}>
                {ColumnHelper.renderDecimal(payableAmount)}
              </Row>
            </List.Item>
          );
        })}

      {commissions.description && (
        <List.Item key={-2}>{commissions.description}</List.Item>
      )}
    </List>
  );

  const renderWithOutAmount = (commissions) => (
    <List size="small">
      {Object.keys(commissions)
        .filter((x) => x.startsWith("commissionRate") && !x.endsWith("Enabled"))
        .map((rate, i) => {
          const commissionRate = commissions[rate];
          if (!commissionRate && commissionRate !== 0) return;

          const extra =
            commissions.extraInstallmentCount > 0 ? (
              <span className="gx-text-success">{` + (${commissions.extraInstallmentCount})`}</span>
            ) : undefined;
          return (
            <List.Item key={i}>
              <Row justify="end" style={{ width: "50%" }}>
                {i === 0 ? (
                  <strong>Tek Çekim : </strong>
                ) : (
                  <strong>
                    {i + 1}
                    {extra} Taksit :{" "}
                  </strong>
                )}
              </Row>
              <Row style={{ width: "50%" }}>
                {renderCommissionRate(commissions[rate])}
              </Row>
            </List.Item>
          );
        })}

      {commissions.description && (
        <List.Item key={-2}>{commissions.description}</List.Item>
      )}
    </List>
  );

  const renderCommisions = () => {
    return props.data?.commissions?.length > 0 ? (
      <Row justify="center">
        {props.data?.commissions?.map((commissions) => (
          <Col xs={24} md={12} lg={12} xl={8} key={commissions.id}>
            <Card
              title={
                <CardLogo
                  {...commissions}
                  rowStyle={{ marginTop: -15, marginBottom: 5 }}
                  imageHeight={30}
                  allList={props.data?.commissions}
                />
              }
              style={{ margin: "10px 0", height: "calc(100% - 20px)" }}
              className="gx-card"
              bodyStyle={{ padding: 0 }}
            >
              {props.amount > 0
                ? renderWithAmount(commissions)
                : renderWithOutAmount(commissions)}
            </Card>
          </Col>
        ))}
      </Row>
    ) : (
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Veri Yok" />
    );
  };

  return (
    <>
      <Row id="installmentArea" style={{ marginBottom: 24, ...props.style }}>
        <Col span={24}>
          <Collapse>
            <Collapse.Panel header="Taksit Seçenekleri">
              {renderCommisions()}
            </Collapse.Panel>
          </Collapse>
        </Col>
      </Row>
    </>
  );
};

export default PosCommissionTable;
