import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";
import NotFound from "./NotFound/NotFound";
import UnAuthorized from "./NotFound/UnAuthorized";

const App = ({ match }) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route
        path={`${match.url}home`}
        component={asyncComponent(() => import("./Home"))}
      />

      <Route
        path={`${match.url}payment-plan`}
        component={asyncComponent(() => import("./PaymentPlan"))}
      />
      <Route
        path={`${match.url}collection-plan`}
        component={asyncComponent(() => import("./CollectionPlan"))}
      />
      <Route
        path={`${match.url}pos-collection`}
        component={asyncComponent(() => import("./PosCollection"))}
      />
      <Route
        path={`${match.url}pos-collection-transaction`}
        component={asyncComponent(() =>
          import("./PosCollection/PosCollectionTransaction.js")
        )}
      />
      <Route
        path={`${match.url}erp-report`}
        component={asyncComponent(() => import("./ErpReport"))}
      />
      <Route
        path={`${match.url}dbs-invoices`}
        component={asyncComponent(() => import("./Dbs"))}
      />
      <Route
        path={`${match.url}payment-request`}
        component={asyncComponent(() => import("./PaymentRequest"))}
      />
      <Route
        path={`${match.url}payment-list`}
        component={asyncComponent(() =>
          import("./PaymentRequest/PaymentList.js")
        )}
      />

      {/* Şifre Değiştir */}
      <Route
        path={`${match.url}change-password`}
        component={asyncComponent(() => import("./Password/ChangePassword"))}
      />

      <Route
        exact
        path={`${match.url}not-authorized-access`}
        component={UnAuthorized}
      />
      <Route component={NotFound} />
    </Switch>
  </div>
);

export default App;
