import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import enTranslation from "./locales/en/common.json";
import trTranslation from "./locales/tr/common.json";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: enTranslation,
    },
    tr: {
      translation: trTranslation,
    },
  },
  lng: "tr", // Başlangıç dili
  fallbackLng: "tr", // Desteği olmayan bir dil kullanıldığında başa dönecek dil seçeneği
  interpolation: {
    escapeValue: false, // HTML etiketlerini metin olarak işaretle
  },
});

export default i18n;
