const typeTemplate = "'${label}' alanı geçerli bir ${type} değil.";
export const defaultValidateMessages = {
  default: "'${Label}' alanı doğrulama hatası",
  required: "'${label}' alanı gerekli.",
  enum: "'${label}' alanı [$ {enum}] değerlerinden biri olmalıdır.",
  whitespace: "'${label}' alanı boş olamaz.",
  date: {
    format: "'${label}' alanı tarih formatı geçersiz.",
    parse: "'${label}' alanı tarih olarak ayrıştırılamadı.",
    invalid: "'${label}' alanı geçersiz bir tarih.",
  },
  types: {
    string: typeTemplate,
    method: typeTemplate,
    array: typeTemplate,
    object: typeTemplate,
    number: typeTemplate,
    date: typeTemplate,
    boolean: typeTemplate,
    integer: typeTemplate,
    float: typeTemplate,
    regexp: typeTemplate,
    email: typeTemplate,
    url: typeTemplate,
    hex: typeTemplate,
  },
  string: {
    len: "'${label}' alanı tam olarak ${len} karakter olmalıdır.",
    min: "'${label}' alanı en az ${min} karakter olmalıdır.",
    max: "'${label}' alanı ${max} karakterden uzun olamaz.",
    range: "'${label}' alanı ${min} ile ${max} karakter arasında olmalıdır.",
  },
  number: {
    len: "'${label}' alanı ${len} değerine eşit olmalıdır.",
    min: "'${label}' alanı ${min}'den az olamaz.",
    max: "'${label}' alanı ${max}'dan büyük olamaz.",
    range: "'${label}' alanı ${min} ile ${max} arasında olmalıdır.",
  },
  array: {
    len: "'${label}' alanı tam olarak ${len} uzunluğunda olmalıdır.",
    min: "'${label}' alanı uzunluğu ${min}'den az olamaz.",
    max: "'${label}' alanı uzunluğu ${max}'dan fazla olamaz.",
    range: "'${label}' alanı uzunluğu ${min} ile ${max} arasında olmalıdır.",
  },
  pattern: {
    mismatch: "'${label}' alanı ${pattern} kalıbıyla eşleşmiyor.",
  },
};

//onInput functions
export const onInputMaxLengthCheck = (e) => {
  if (e.target.value.length > e.target.maxLength) {
    e.target.value = e.target.value.slice(0, e.target.maxLength);
  }
};

//onKeyDown functions
export const onKeyDownNumberOnly = (e) => {
  if (
    isNaN(e.key) &&
    !(
      e.key === "Backspace" ||
      e.key === "Delete" ||
      e.key.startsWith("Arrow") ||
      e.shiftKey ||
      e.ctrlKey
    )
  ) {
    e.preventDefault();
  }
};
