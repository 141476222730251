import React, { useState,useEffect } from "react";
import { Button, notification, Form, Input } from "antd";
import RequestHandler from "../util/RequestHandler";
import Screen from "../util/Screens";
const FormItem = Form.Item;

const ForgotPassword = (props) => {
  const [loading, setLoading] = useState(false);
  const [logoUrl, setLogoUrl] = useState();
  const [tenantUrl, setTenantUrl] = useState();
  useEffect(() => {
    let basePath = window.location.href.split('/forget-password')[0];
    let newLogoUrl = "";
     RequestHandler.get(`/FirmAuth/GetLoginEndpoints`).then((response) => {
      response.domain.map((item)=>{
       if(basePath == item.endpoint){
         newLogoUrl = item.logoUrl;
        setTenantUrl(item.endpoint)
       }
     })
    if(newLogoUrl == ""){
       setLogoUrl(null)
     }else{
       setLogoUrl(newLogoUrl)
     }
     });
  }, []);
  
  const onFinishFailed = (errorInfo) => {
    setLoading(false);
  };

  const onFinish = (values) => {
    setLoading(true);
    RequestHandler.post(`/FirmAuth/ForgotPassword`, {
      Email: values.email,
      ScreenOption: Screen.screen,
      TenantUrl: tenantUrl
    }).then((response) => {
      setLoading(false);
      if (response.success) {
        props.history.push("/");
      }
    });
  };

  return (
    <div className="gx-login-container" style={{ backgroundColor: "#f0f8ff" }}>
      <div className="gx-login-content">
        <div className="gx-login-header">
          {Screen.screen === 0 ? (
            logoUrl === undefined ? null :
            <img
              src={logoUrl === null ? require("assets/images/finekralogo.png") : logoUrl}
              alt="paratic"
              title="paratic"
              style={{ width: "40%" }}
            />
          ) : (
            <img
              src={require("assets/images/imzaPosLogo.png")}
              alt="paratic"
              title="paratic"
              style={{ width: "40%" }}
            />
          )}
        </div>
        <div className="gx-mb-4">
          <h3>Şifreni mi unuttun ?</h3>
          <p>
            E-postanızı gönderdiğinizde, e-postanıza şifrenizi yenilemeniz için
            bir link gönderilecektir.
          </p>
        </div>

        <Form
          initialValues={{ remember: true }}
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="gx-signin-form gx-form-row0"
        >
          <FormItem
            name="email"
            rules={[
              { required: true, message: "Lütfen mail adresinizi girin!" },
            ]}
          >
            <Input
              className="gx-input-lineheight"
              type="email"
              placeholder="Email adresinizi girin"
            />
          </FormItem>

          <FormItem>
            <Button type="primary" htmlType="submit" loading={loading}>
              GÖNDER
            </Button>
          </FormItem>
        </Form>
      </div>
    </div>
  );
};

export default ForgotPassword;
