import React from "react";
import { ColumnHelper } from "util/TableHelper";

import { Empty, Col, Row, Card, List, Radio, Form } from "antd";
import CardLogo from "./CardLogo";
import B2BFileModal from "./B2BFileModal";

const PosSelectableInstallmentTable = (props) => {
  const { useCommissionsForAvailableInstallment } = props.data;

  const calculatePayableAmount = (
    amount,
    commissionRate,
    bankId,
    isCommissionFromDealer
  ) => {
    let payableAmount = amount;
    if (useCommissionsForAvailableInstallment) {
      payableAmount = amount + (amount / 100) * commissionRate;
    }

    if (isCommissionFromDealer && useCommissionsForAvailableInstallment) {
      payableAmount = (amount / (100 - commissionRate)) * 100;
    }

    if (bankId == 856) {
      payableAmount = amount / ((100 - commissionRate) / 100);
    }

    return payableAmount;
  };

  const onChangeRadio = (e) => {
    if (props.onChange) {
      props.onChange(e.target.value, {
        bankId: e.target.bankId,
        installmentCount: e.target.installmentCount,
        cardType: e.target.cardType,
      });
    }
  };

  const renderList = (commissions) => (
    <List size="small">
      {Object.keys(commissions)
        .filter((x) => x.startsWith("commissionRate") && !x.endsWith("Enabled"))
        .map((rate, i) => {
          const commissionRate = commissions[rate];
          if (!commissionRate && commissionRate !== 0) return;

          const totalInstallment =
            i === 0 ? 1 : i + 1 + commissions.extraInstallmentCount;
          const payableAmount = calculatePayableAmount(
            props.amount ?? 0,
            commissionRate,
            commissions.bankId,
            commissions.isCommissionFromDealer
          );
          const installmentAmount = payableAmount / totalInstallment;

          const extra =
            commissions.extraInstallmentCount > 0 ? (
              <span className="gx-text-success">{`+(${commissions.extraInstallmentCount})`}</span>
            ) : undefined;
          return (
            <List.Item key={i}>
              <Radio
                value={`${commissions.bankId}/${commissions.id}/${
                  commissions.cardName
                }/${i + 1}`}
                bankId={commissions.bankId}
                installmentCount={i + 1}
                style={{ width: "100%" }}
                commissionDefinitionId={commissions.id}
                cardType={commissions.cardName}
                disabled={
                  props.disabledOptions != undefined &&
                  props?.disabledOptions === true
                }
              >
                {i === 0 ? (
                  <span>
                    {i + 1} x {ColumnHelper.renderDecimal(installmentAmount)}
                  </span>
                ) : (
                  <span>
                    {i + 1}
                    {extra} x {ColumnHelper.renderDecimal(installmentAmount)}
                  </span>
                )}
              </Radio>
            </List.Item>
          );
        })}

      {commissions.description && (
        <List.Item key={-2}>{commissions.description}</List.Item>
      )}
    </List>
  );

  return props.data?.commissions?.length > 0 ? (
    <Form.Item
      name="bankIdInstallment"
      label={
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ marginRight: "10px" }}>Ödeme Seçenekleri :</span>
          <B2BFileModal filePath={props.filePath} />
        </div>
      }
      colon={false}
      labelCol={{ span: 24, style: { height: "66px" } }}
      rules={[
        {
          required:
            props.disabledOptions != undefined &&
            props?.disabledOptions === true
              ? false
              : true,
          message: "Ödeme seçeneklerinden seçim yapılmalıdır.",
        },
      ]}
    >
      <Radio.Group
        onChange={onChangeRadio}
        value={props.radioValue}
        style={{ width: "100%" }}
      >
        <Row justify="center" gutter={10}>
          {props.data?.commissions?.map((commissions) => (
            <Col xs={20} md={8} lg={6} key={commissions.id}>
              <Card
                title={
                  <CardLogo
                    {...commissions}
                    rowStyle={{ marginTop: -15, marginBottom: 5 }}
                    imageHeight={30}
                    allList={props.data?.commissions}
                  />
                }
                style={{ margin: "10px 0", height: "calc(100% - 20px)" }}
                className="gx-card"
                bodyStyle={{ padding: 0 }}
              >
                {renderList(commissions)}
              </Card>
            </Col>
          ))}
        </Row>
      </Radio.Group>
    </Form.Item>
  ) : (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description="Ödeme seçenekleri için kart bilgilerinizi giriniz."
    />
  );
};

export default PosSelectableInstallmentTable;
